<template>
  <div>
    <a-form layout="vertical">
      <a-row :gutter="16">
        <a-col :span="16">
          <a-form-item
            label="Nama Shift"
          >
            <a-input v-model="newShift.nama" size="large" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item>
            <span slot="label">
              Absensi Dibuka (Menit)&nbsp;
              <a-tooltip title="Waktu dihitung (n) menit sebelum jam masuk dimulai">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <a-input-number
              :min="0"
              :max="120"
              step="1"
              size="large"
              v-model="newShift.absensi_dibuka"
              class="w-100"
            />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item>
            <span slot="label">
              Absensi Ditutup (Menit)&nbsp;
              <a-tooltip title="Waktu dihitung (n) menit setelah jam keluar dimulai">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <a-input-number
              :min="0"
              :max="120"
              step="1"
              size="large"
              v-model="newShift.absensi_ditutup"
              class="w-100"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <p class="mb-3">Terapkan Shift untuk semua daftar Shift</p>
      <a-row :gutter="16" class="border rounded px-2 pt-4 m-0">
        <a-col :span="4">
          <a-form-item
            label="Jam Masuk"
          >
            <a-time-picker class="w-100" :value="shiftDefault.jam_masuk" format="HH:mm" size="large" @change="(e) => handleChange(e, 'all', 'jam_masuk')" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item
            label="Jam Keluar"
          >
            <a-time-picker class="w-100" :value="shiftDefault.jam_keluar" format="HH:mm" size="large" @change="(e) => handleChange(e, 'all', 'jam_keluar')" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item
            label="Toleransi (Menit)"
          >
            <a-input-number
              :min="0"
              :max="120"
              step="1"
              size="large"
              v-model="shiftDefault.toleransi"
              @change="(e) => handleChange(e, 'all', 'toleransi')"
              class="w-100"
            />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item
            label="Jam Istirahat"
          >
            <a-time-picker class="w-100" :value="shiftDefault.jam_istirahat" format="HH:mm" size="large" @change="(e) => handleChange(e, 'all', 'jam_istirahat')" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item
            label="Durasi Istirahat (menit)"
          >
          <a-input-number
            :min="0"
            :max="120"
            step="1"
            size="large"
            v-model="shiftDefault.periode_jam_istirahat"
            @change="(e) => handleChange(e, 'all', 'periode_jam_istirahat')"
            class="w-100"
          />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item
            label="Jumlah Jam Kerja"
          >
            <a-input v-model="shiftDefault.jumlah_jam_kerja" :disabled="true" size="large" />
          </a-form-item>
        </a-col>
      </a-row>
      <p class="mb-3 mt-4">Daftar Shift ({{`${hasActive} hari diaktifkan`}})</p>
      <a-row :gutter="16" class="">
        <a-col :span="24">
          <a-table
            :columns="dayColumns"
            :data-source="dayData"
            :pagination="false"
            bordered
            size="large"
          >
          <div slot="status" slot-scope="value, record">
            <a-switch
              :checked="value === 'active' ? true : false "
              @change="onChangeStatusDay(record)"
            >
              <a-icon slot="checkedChildren" type="check" />
              <a-icon slot="unCheckedChildren" type="close" />
            </a-switch>
          </div>
          <div slot="jam_masuk" slot-scope="value, record">
            <a-time-picker class="w-100" :value="value" format="HH:mm" size="large" @change="(e) => handleChange(e, 'one', 'jam_masuk', record)" />
          </div>
          <div slot="jam_keluar" slot-scope="value, record">
            <a-time-picker class="w-100" :value="value" format="HH:mm" size="large" @change="(e) => handleChange(e, 'one', 'jam_keluar', record)" />
          </div>
          <div slot="toleransi" slot-scope="value, record">
            <a-input-number
              :min="0"
              :max="120"
              step="1"
              size="large"
              :value="value"
              @change="(e) => handleChange(e, 'one', 'toleransi', record)"
              class="w-100"
            />
          </div>
          <div slot="jam_istirahat" slot-scope="value, record">
            <a-time-picker class="w-100" :value="value" format="HH:mm" size="large" @change="(e) => handleChange(e, 'one', 'jam_istirahat', record)" />
          </div>
          <div slot="periode_jam_istirahat" slot-scope="value, record">
            <a-input-number
              :min="0"
              :max="120"
              step="1"
              size="large"
              :value="value"
              @change="(e) => handleChange(e, 'one', 'periode_jam_istirahat', record)"
              class="w-100"
            />
          </div>
          <div slot="jumlah_jam_kerja" slot-scope="value">
            <a-input :value="value" :disabled="true" size="large" />
          </div>
          </a-table>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
export default {
  props: {
    newShift: {
      type: Object,
      required: true,
    },
    shiftDefault: {
      type: Object,
      required: true,
    },
    dayColumns: {
      type: Array,
      required: true,
    },
    dayData: {
      type: Array,
      required: true,
    },
    hasActive: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onChangeStatusDay(data) {
      this.$emit('change-status-day', data)
    },
    handleChange(value, type, column, data) {
      const payload = {
        value,
        type,
        column,
        data,
      }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style>

</style>
